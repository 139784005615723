<template>
<div class="modal fade" ref="crossoverModal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" v-if="script">
            <div class="modal-header">
                <h5 class="modal-title">{{ script.name }} - EMAs / Crossovers</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="table table-hover table-bordered table-responsive">
                    <tbody>
                    <tr>
                        <th class="text-center" scope="col">Daily 4 EMA</th>
                        <th class="text-center" scope="col">Daily 13 EMA</th>
                        <th class="text-center" scope="col">Daily 40 EMA</th>
                        <th class="text-center" scope="col">Daily 80 EMA</th>
                        <th class="text-end" scope="col">Daily 200 EMA</th>
                    </tr>
                    <tr>
                        <td class="text-end">{{ roundOff(script.Day4EMA) }}</td>
                        <td class="text-end">{{ roundOff(script.Day13EMA) }}</td>
                        <td class="text-end">{{ roundOff(script.Day40EMA) }}</td>
                        <td class="text-end">{{ roundOff(script.Day80EMA) }}</td>
                        <td class="text-end">{{ roundOff(script.Day200EMA) }}</td>
                    </tr>
                    </tbody>
                </table>
                <table class="table table-hover table-bordered table-responsive">
                    <tbody>
                    <tr>
                        <th class="text-center" scope="col">Weekly 4 EMA</th>
                        <th class="text-center" scope="col">Weekly 13 EMA</th>
                        <th class="text-center" scope="col">Weekly 40 EMA</th>
                        <th class="text-center" scope="col">Weekly 80 EMA</th>
                        <th class="text-end" scope="col">Weekly 200 EMA</th>
                    </tr>
                    <tr>
                        <td class="text-end">{{ roundOff(script.Week4EMA) }}</td>
                        <td class="text-end">{{ roundOff(script.Week13EMA) }}</td>
                        <td class="text-end">{{ roundOff(script.Week40EMA) }}</td>
                        <td class="text-end">{{ roundOff(script.Week80EMA) }}</td>
                        <td class="text-end">{{ roundOff(script.Week200EMA) }}</td>
                    </tr>
                    </tbody>
                </table>
                <form id="view-crossover-form" @submit.prevent="viewCrossover" v-if="!showCrossovers">
                    <div class="row mb-3">
                        <div class="col">
                            <label for="crossover-timeframe" class="form-label">Timeframe</label>
                            <select class="form-select" id="crossover-timeframe" required
                                    v-model.number="data.timeframe">
                                <option value="0">Daily</option>
                                <option value="1">Weekly</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="crossover-series1" class="form-label">Series 1</label>
                            <select class="form-select" id="crossover-series1" required
                                    v-model.number="data.series1">
                                <option value="">Select</option>
                                <option v-for="item in series" :key="item" :value="item">{{ item }}</option>
                            </select>
                        </div>
                        <div class="col">
                            <label for="crossover-series2" class="form-label">Series 2</label>
                            <select class="form-select" id="crossover-series2" required
                                    v-model.number="data.series2">
                                <option value="">Select</option>
                                <option v-for="item in series" :key="item" :value="item">{{ item }}</option>
                            </select>
                        </div>
                    </div>
                </form>
                <div class="table-container" v-if="showCrossovers">
                    <table class="table table-hover table-bordered table-responsive">
                        <tbody>
                        <tr>
                            <th scope="col">#</th>
                            <th class="text-center" scope="col">Price</th>
                            <th class="text-center" scope="col">Date</th>
                            <th class="text-center" scope="col">{{ getSeries1DisplayName() }}</th>
                            <th class="text-center" scope="col">{{ getSeries2DisplayName() }}</th>
                            <th class="text-end" scope="col">Crossover Point</th>
                        </tr>
                        <tr v-for="(crossover, index) in crossovers" :key="crossover._id">
                            <th scope="row">{{ index + 1 }}</th>
                            <td class="text-end">{{ roundOff(crossover.close) }}</td>
                            <td class="text-center">{{ getDate(crossover.date) }}</td>
                            <td class="text-end">{{ roundOff(crossover[getSeries1Name()]) }}</td>
                            <td class="text-end">{{ roundOff(crossover[getSeries2Name()]) }}</td>
                            <td class="text-end">{{ roundOff(crossover.crossoverPoint) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer justify-content-start" v-if="showCrossovers">
                <button type="button" class="btn btn-secondary" @click.prevent="showCrossovers = false">Back</button>
            </div>
            <div class="modal-footer justify-content-start" v-else>
                <button type="submit" class="btn btn-primary" form="view-crossover-form" :disabled="loading">
                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="loading" class="visually-hidden">...</span>
                    <span v-if="!loading">View Crossovers</span>
                </button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {Modal} from "bootstrap";
import moment from "moment-timezone";

export default {
    name: "CrossoverModal",
    data: () => ({
        script: null,
        series: [4, 13, 40, 80, 200],
        data: {
            timeframe: 0,
            series1: 4,
            series2: 13
        },
        loading: false,
        crossovers: [],
        showCrossovers: false
    }),
    methods: {
        roundOff(number) {
            return parseFloat(number).toFixed(2);
        },
        getSeries1DisplayName() {
            const cType = this.data.timeframe === 0 ? 'Daily ' : 'Weekly '
            return cType + this.data.series1 + 'EMA';
        },
        getSeries2DisplayName() {
            let cType = this.data.timeframe === 0 ? 'Daily ' : 'Weekly '
            return cType + this.data.series2 + 'EMA';
        },
        getSeries1Name() {
            const cType = this.data.timeframe === 0 ? 'Day' : 'Week'
            return cType + this.data.series1 + 'EMA';
        },
        getSeries2Name() {
            let cType = this.data.timeframe === 0 ? 'Day' : 'Week'
            return cType + this.data.series2 + 'EMA';
        },
        getDate(timestamp) {
            return moment.utc(timestamp).tz("Asia/Kolkata").format('DD-MMM-YYYY');
        },
        openModal(script) {
            this.script = script;
            this.data = {
                timeframe: 0,
                series1: 4,
                series2: 13
            }
            this.crossovers = [];
            this.showCrossovers = false;
            this.loading = false;
            const crossoverModal = new Modal(this.$refs.crossoverModal);
            crossoverModal.show()
        },
        async viewCrossover() {
            try {
                this.loading = true;
                if (this.data.series1 === this.data.series2) {
                    alert('Series 1 and Series 2 cannot be same')
                    return;
                }
                this.crossovers = await this.$store.dispatch('scripts/getCrossover', {
                    id: this.script._id,
                    type: this.data.timeframe,
                    series1: this.data.series1,
                    series2: this.data.series2,
                })
                this.showCrossovers = true
            } catch (err) {
                alert(err.message);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
form#view-crossover-form, .table-container {
    border-top: 2px solid #655e5e;
    padding-top: 1rem;
}
.table-container {
    width: 100%;
    overflow-x: auto;
}
@media (min-width: 800px) {
    .modal-dialog {
        max-width: 750px;
    }
}
</style>
