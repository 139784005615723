import axios from "@/axios";

const state = {
  page: 1,
  total: 0,
  limit: 25,
  lastSyncDate: null,
  term: '',
  list: []
};

const getters = {
  getCurrentPage(state) {
    return state.page
  },
  getLastSyncDate(state) {
    return state.lastSyncDate
  },
  getTotalPage(state) {
    return Math.ceil(state.total / state.limit)
  },
  getScripts(state) {
    return state.list
  }
};

const mutations = {
  setScripts(state, data) {
    state.total = data.total;
    state.list = data.scripts;
    state.lastSyncDate = data.lastSyncDate;
  },
  setRsi(state, {scriptId, rsiData}) {
    const index = state.list.findIndex(script => script._id === scriptId);
    if (index > -1) {
      state.list[index] = {
        ...state.list[index],
        ...rsiData
      }
    }
  },
  setPage(state, page) {
    state.page = page;
  },
  setTerm(state, term) {
    state.term = term;
  },
  exactSearch(state, term) {
    state.list = state.list.filter((script) => script.code.toLowerCase() === term.toLowerCase() || script.name.toLowerCase() === term.toLowerCase())
    state.total = state.list.length
  }
};

const actions = {
  async fetchScripts({ state, commit, dispatch }) {
    let url = `?offset=${(state.page - 1) * state.limit}&limit=${state.limit}`;
    if (state.term) {
      url = `${url}&term=${encodeURIComponent(state.term)}`
    }
    const res = await axios.get(`/api/scripts/lookup${url}`);
    commit('setScripts', {
      scripts: res.data.paginatedResult.data,
      total: res.data.paginatedResult.count,
      lastSyncDate: res.data.maxDate
    })
    res.data.paginatedResult.data.forEach((script) => {
      dispatch('getRSI', script._id);
    });
  },
  async getCrossover(_, payload) {
    let params = `type=${payload.type}&series1=${payload.series1}&series2=${payload.series2}`;
    const res = await axios.get(`/api/scripts/crossover/${payload.id}?${params}`);
    return res.data;
  },
  async getRSI({ commit }, scriptId) {
    const res = await axios.get(`/api/scripts/rsi/${scriptId}`);
    commit('setRsi', {
      scriptId,
      rsiData: res.data
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
