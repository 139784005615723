<template>
    <div class="py-5">
        <h1 class="display-4">Full list of symbols</h1>
        <div class="mt-4 d-flex align-items-center justify-content-between">
            <form class="search-form" @submit.prevent="exactSearch">
                <input
                    type="text"
                    class="form-control rounded-pill d-inline-block w-auto ps-4"
                    placeholder="Filter by symbol"
                    v-model="search"
                />
                <button v-if="search" type="button" class="bg-transparent border-0" @click="search = ''" style="margin-left: -1.5rem">
                    <i class="fa-solid fa-xmark"></i>
                </button>
                <button type="submit" class="py-2 pe-3 ps-2 rounded-pill border-0">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </button>
            </form>
            <p class="m-0 p-0" v-if="lastSyncDate">Last Updated: <strong>{{ new Date(lastSyncDate).toDateString() }}</strong></p>
        </div>

        <div class="table-container">
            <table class="table table-striped mt-4 table-responsive">
                <thead>
                <tr>
                    <th scope="col">Symbol</th>
                    <th class="text-center" scope="col">Market Lot</th>
                    <th class="text-center" scope="col">Last Price</th>
                    <th class="text-center" scope="col">Daily Volume</th>
                    <th class="text-center" scope="col">Weekly Volume</th>
                    <th class="text-center" scope="col">TTW</th>
                    <th class="text-center" scope="col">TTD</th>
                    <th class="text-center" scope="col">W RSI</th>
                    <th class="text-center" scope="col">D RSI</th>
                    <th class="text-center" scope="col">ATH</th>
                    <th class="text-end" scope="col">EMAs / Crossovers</th>
                </tr>
                </thead>
                <tbody v-if="!loading">
                <tr v-for="script in scripts" :key="script.code">
                    <td>{{ script.name }}</td>
                    <td class="text-end">{{ script.marketLot }}</td>
                    <td class="text-end">{{ roundOff(script.lastPrice) }}</td>
                    <td class="text-end">
                        {{ script.volume }}
                        <span v-if="script.volume > script.Day20SMA">(+)</span>
                        <span v-if="script.volume < script.Day20SMA">(-)</span>
                    </td>
                    <td class="text-end">
                        {{ script.WeeklyVolume }}
                        <span v-if="script.WeeklyVolume > script.Week20VolumeSMA">(+)</span>
                        <span v-if="script.WeeklyVolume < script.Week20VolumeSMA">(-)</span>
                    </td>
                    <td>
                        <div class="d-flex flex-column align-items-center">
                            <div :class="`color-box color-box-${getWeeklyColor(script)}`"></div>
                            <span>{{ getWeeklyStatus(script) || '-' }}</span>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-column align-items-center">
                            <div v-if="getDailyColor(script)" :class="`color-box color-box-${getDailyColor(script)}`"></div>
                            <span>{{ getDailyStatus(script) || '-' }}</span>
                        </div>
                    </td>
                    <td class="text-end">{{ script.weeklyRSI ? roundOff(script.weeklyRSI) : 'Loading...' }}</td>
                    <td class="text-end">{{ script.dailyRSI ? roundOff(script.dailyRSI) : 'Loading...' }}</td>
                    <td class="text-end">{{ roundOff(script.ath) }}</td>
                    <td class="text-end">
                        <button type="button" class="btn btn-secondary btn-sm" @click.prevent="openModal(script)">
                            EMAs / Crossovers
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="d-block text-center w-100 overflow-hidden" v-if="loading">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="d-block text-center w-100 overflow-hidden" v-if="!scripts.length && !loading">
                <p>No results found.</p>
            </div>
        </div>
        <nav v-if="!loading" class="d-flex align-items-center justify-content-between mt-4">
            <ul class="pagination m-0">
                <li class="page-item" :class="{'disabled': currentPage === 1}">
                    <a class="page-link text-dark" href="#" @click.prevent="changePage(currentPage - 1)">Previous</a>
                </li>
                <li class="page-item"
                    v-for="(page, index) in allPages" :key="index"
                    :class="{'active': currentPage === page, 'disabled': page === '...'}">
                    <a class="page-link text-dark" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{'disabled': currentPage === totalPages}">
                    <a class="page-link text-dark" href="#" @click.prevent="changePage(currentPage + 1)">Next</a>
                </li>
            </ul>
            <p class="p-0">Page {{ currentPage }} of {{ totalPages }}</p>
        </nav>
    </div>
    <CrossoverModal ref="crossoverModal"/>
</template>

<script>
import CrossoverModal from "@/components/CrossoverModal";
import { debounce } from 'vue-debounce'

export default {
    name: "HomeView",
    components: {CrossoverModal},
    data: () => ({
        search: '',
        loading: false,
        debouncedSearch: null,
    }),
    watch: {
        search() {
            if (this.debouncedSearch) {
                this.debouncedSearch();
            }
        },
    },
    computed: {
        currentPage() {
            return this.$store.getters['scripts/getCurrentPage'];
        },
        lastSyncDate() {
            return this.$store.getters['scripts/getLastSyncDate'];
        },
        totalPages() {
            return this.$store.getters['scripts/getTotalPage'];
        },
        allPages() {
            const currentPage = this.$store.getters['scripts/getCurrentPage'];
            const totalPages = this.$store.getters['scripts/getTotalPage'];
            const pages = [];
            if (totalPages <= 10) {
                for (let i = 1; i <= totalPages; i++) {
                    pages.push(i);
                }
            } else {
                if (currentPage > 5) {
                    pages.push('...')
                    for (let i = currentPage - 4; i <= currentPage + 5; i++) {
                        pages.push(i);
                    }
                    pages.push('...')
                } else {
                    for (let i = 1; i <= 10; i++) {
                        pages.push(i);
                    }
                    pages.push('...')
                }
            }
            return pages;
        },
        scripts() {
            return this.$store.getters['scripts/getScripts']
        }
    },
    methods: {
        async sleep(time) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, time)
            })
        },
        async exactSearch() {
            if (!this.loading) {
                await this.searchScript();
                await this.sleep(100);
            }
            while (this.loading) {
                await this.sleep(200);
                console.log('wait')
            }
            this.$store.commit('scripts/exactSearch', this.search)
            console.log('here')
        },
        roundOff(number) {
            return parseFloat(number).toFixed(2);
        },
        async fetchScripts() {
            try {
                this.loading = true;
                await this.$store.dispatch('scripts/fetchScripts')
            } finally {
                this.loading = false
            }
        },
        async searchScript() {
            this.$store.commit('scripts/setTerm', this.search)
            this.$store.commit('scripts/setPage', 1)
            await this.fetchScripts()
        },
        openModal(script) {
            this.$refs.crossoverModal.openModal(script)
        },
        changePage(page) {
            this.$store.commit('scripts/setPage', page)
            this.fetchScripts()
        },
        getColor(script, isDay) {
            const prefix = isDay ? 'Day' : 'Week';
            if (script[`${prefix}40EMA`] > script[`${prefix}80EMA`] && script[`${prefix}80EMA`] > script[`${prefix}200EMA`]) {
                return 'green'
            } else if (script[`${prefix}80EMA`] > script[`${prefix}40EMA`] && script[`${prefix}40EMA`] > script[`${prefix}200EMA`]) {
                return 'lightgreen'
            } else if (script[`${prefix}200EMA`] > script[`${prefix}80EMA`] && script[`${prefix}80EMA`] > script[`${prefix}40EMA`]) {
                return 'red'
            } else if (script[`${prefix}200EMA`] > script[`${prefix}40EMA`] && script[`${prefix}40EMA`] > script[`${prefix}80EMA`]) {
                return 'orange'
            }
        },
        getDailyColor(script) {
            return this.getColor(script, true);
        },
        getWeeklyColor(script) {
            return this.getColor(script, false);
        },
        getDailyStatus(script) {
            const color = this.getColor(script, true);
            switch (color) {
                case 'green':
                    return 'Bullish';
                case 'lightgreen':
                    return 'Mild Bearish'
                case 'red':
                    return 'Bearish'
                case 'orange':
                    return 'Mild Bullish'
            }
        },
        getWeeklyStatus(script) {
            const color = this.getColor(script, false);
            switch (color) {
                case 'green':
                    return 'Bullish';
                case 'lightgreen':
                    return 'Mild Bearish'
                case 'red':
                    return 'Bearish'
                case 'orange':
                    return 'Mild Bullish'
            }
        }
    },
    mounted() {
        this.debouncedSearch = debounce(() => {
            this.searchScript();
        }, '500ms');
        this.fetchScripts()
    }
};
</script>

<style scoped>
td, th {
    vertical-align: middle;
}

.page-item.active .page-link {
    color: white !important;
    background: #212529 !important;
    border-color: #212529 !important;
}
.table-container {
    width: 100%;
    overflow-x: auto;
}
.search-form input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.search-form button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
</style>
